import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Shop from "./pages/Shop/Shop";
import SingleProduct from "./pages/SingleProduct/SingleProduct";
import Contact from "./pages/Contact/Contact";
import Footer from "./components/Footer/Footer";
import PrivatPolicy from "./pages/PrivatPolicy/PrivatPolicy";
import RefundPolicy from "./pages/RefundPolicy/RefundPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import Cart from "./pages/Cart/Cart";
import CheckOut from "./pages/CheckOut/CheckOut";
import { Toaster } from "react-hot-toast";
import ConfirmOrder from "./components/ConfirmOrder/ConfirmOrder";
import OrderSuccess from "./components/OrderSuccess/OrderSuccess";
import BasicLevelSeopackage from "./components/Package/BasicLevelSeopackage";
import EcommmerceSeopackage from "./components/Package/EcommmerceSeopackage";
import StarterLevelSeopackage from "./components/Package/StarterLevelSeopackage";
import PlusLevelSeopackage from "./components/Package/PlusLevelSeopackage";
import ProLevelSeopackage from "./components/Package/ProLevelSeopackage";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/product/:id" element={<SingleProduct />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<CheckOut />} />
        <Route path="/confirm/order" element={<ConfirmOrder />} />
        <Route
          path="/product/basiclevelseopackage"
          element={<BasicLevelSeopackage />}
        />
        <Route
          path="/product/ecommmerceseopackage"
          element={<EcommmerceSeopackage />}
        />
        <Route
          path="/product/starterlevelseopackage"
          element={<StarterLevelSeopackage />}
        />
        <Route
          path="/product/pluslevelseopackage"
          element={<PlusLevelSeopackage />}
        />
        <Route
          path="/product/prolevelseopackage"
          element={<ProLevelSeopackage />}
        />
        <Route path="/privacy-policy" element={<PrivatPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/success" element={<OrderSuccess />} />
      </Routes>
      <Footer />
      <Toaster />
    </Router>
  );
}

export default App;
