import React from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="top-footer">
          <div className="footer-top-link">
            <div className="footer-icon">
              <img src="/images/massage.png" alt="" />
            </div>
            <div className="footer-contact-link">
              <a href="mailto:tshabbir@venturespoint.com">
                contact@lineconsultant.com
              </a>
            </div>
          </div>
        </div>
        <span className="footer-line"></span>
        <div className="buttom-footer">
          <div className="footer-container">
            <div className="footer-items">
              <div className="footer-item">
                <div className="footer-box">
                  <div className="title-footer">
                    <h4>Address</h4>
                  </div>
                  <div className="footer-title-2">
                    <p>3525 W Peterson Ave Chicago 60659</p>
                    <br />
                    <br />
                    <span>Copyrights By </span>
                    <a href={"#header"}> Line Consultant </a>
                    <span>2022</span>
                  </div>
                </div>
                <div className="footer-box">
                  <div className="title-footer">
                    <h4>Quick Links</h4>
                  </div>
                  <div className="footer-ul">
                    <ul>
                      <li>
                        <button onClick={() => navigate("/privacy-policy")}>
                          <a href="#header">Privacy Policy</a>
                        </button>
                      </li>
                      <li>
                        <button onClick={() => navigate("/refund-policy")}>
                          <a href="#header">Refund Policy</a>
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => navigate("/terms-and-conditions")}
                        >
                          <a href="#header">Terms & Conditions</a>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-box">
                  <div className="title-footer">
                    <h4>Menu</h4>
                  </div>
                  <div className="footer-ul">
                    <ul>
                      <li>
                        <button onClick={() => navigate("/")}>
                          <a href="#header">Home</a>
                        </button>
                      </li>
                      <li>
                        <button onClick={() => navigate("/about")}>
                          <a href="#header">About</a>
                        </button>
                      </li>
                      <li>
                        <button onClick={() => navigate("/shop")}>
                          <a href="#header">Shop</a>
                        </button>
                      </li>
                      <li>
                        <button onClick={() => navigate("/contact")}>
                          <a href="#header">Contact</a>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
