export const itemAddToCart =
  (product, name, price, image, quantity) => async (dispatch, getState) => {
    dispatch({
      type: "addToCart",
      payload: {
        product,
        name,
        price,
        image,
        quantity,
      },
    });

    localStorage.setItem(
      "cartItems",
      JSON.stringify(getState().cart.cartItems)
    );
  };

export const shippingInfo = (data) => (dispatch) => {
  dispatch({ type: "SaveShippingInfo", payload: data });
  localStorage.setItem("shippingInfo", JSON.stringify(data));
};
