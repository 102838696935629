import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";
import "./Shop.css";

const Shop = () => {
  return (
    <section className="shop-page">
      <div className="main-shop">
        <div className="shop-items">
          <div className="shop-text">
            <p> Showing all 5 results</p>
          </div>
          <div className="shop-cart">
            <Cart
              id={"basiclevelseopackage"}
              img="/images/traditional-to-enterprise-seo-6229f8159834d-sej-300x300.png"
              name="Basic Level Seo package"
              price="$99"
            />
            <Cart
              id={"ecommmerceseopackage"}
              img="/images/seo-idea-lightbulbs-ss-1920-300x300.jpg"
              name="Ecommmerce Seo package"
              price="$199"
            />
            <Cart
              id={"starterlevelseopackage"}
              img="/images/search-engine-optimization-services-300x300.jpg"
              name="Starter Level Seo package"
              price="$299"
            />
            <Cart
              id={"pluslevelseopackage"}
              img="/images/Best-SEO-Agencies-300x300.jpg"
              name="Plus Level Seo package"
              price="$399"
            />
            <Cart
              id={"prolevelseopackage"}
              img="/images/seo-concept-1024x683-1-300x300.jpg"
              name="Pro Level Seo package"
              price="$499"
            />
          </div>
        </div>
      </div>
      <div className="whats-app">
        <a href="tel:+8183924439">
          <IoLogoWhatsapp />
          <span>Chat With Us</span>
        </a>
      </div>
    </section>
  );
};

export default Shop;

function Cart({ id, img, name, price }) {
  return (
    <Link to={`/product/${id}`}>
      <div className="main-card">
        <div className="card-img">
          <img src={img} alt={name} />
        </div>
        <h4>{name}</h4>
        <p>{price}</p>
      </div>
    </Link>
  );
}
